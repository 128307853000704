import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '@/store/modules/authModule'

//all
import Login from '@/views/Login.vue'

//admin page
import AdminDashboard from '@/views/Admin/AdminDashboard.vue'
import AllUsers from '@/views/Admin/AllUsers.vue'
import AllGames from '@/views/Admin/AllGames.vue'
import AllQuestions from '@/views/Admin/AllQuestions.vue'

//user page
import UserDashboard from '@/views/User/UserDashboard.vue'
import MyGame from '@/views/User/MyGame.vue'
import CreateGame from '@/views/User/CreateGame.vue'

Vue.use(VueRouter)

function adminRouteGuard(to, from, next) {
  if (auth.state.role === 0 || auth.state.role === 1) {
    next()
  } else if (auth.state.isLoggedIn) {
    next({ name: 'UserDashboard' })
  } else {
    next('/')
  }
}

function redirectToDashboardHandler(to, from, next) {
  if (auth.state.isLoggedIn) {
    if (auth.state.role === 0 || auth.state.role === 1) {
      return next({ name: 'AdminDashboard' })
    } else {
      if (to.name === 'UserDashboard') {
        return next()
      }
      next({ name: 'UserDashboard' })
    }
  } else next()
}

const routes = [
  // admin routes
  {
    path: '/admin/dashboard',
    name: 'AdminDashboard',
    component: AdminDashboard,
    beforeEnter: adminRouteGuard,
  },
  {
    path: '/admin/all-users',
    name: 'AllUsers',
    component: AllUsers,
    beforeEnter: adminRouteGuard,
  },
  {
    path: '/admin/all-question',
    name: 'AllQuestions',
    component: AllQuestions,
    beforeEnter: adminRouteGuard,
  },
  {
    path: '/admin/all-games',
    name: 'AllGames',
    component: AllGames,
    beforeEnter: adminRouteGuard,
  },

  // user routes
  {
    path: '/dashboard',
    name: 'UserDashboard',
    component: UserDashboard,
    beforeEnter: redirectToDashboardHandler,
  },
  {
    path: '/create-game/:id?',
    name: 'CreateGame',
    component: CreateGame,
  },
  {
    path: '/my-game',
    name: 'MyGame',
    component: MyGame,
  },

  //all users routes
  {
    path: '*',
    name: 'Login',
    component: Login,
    beforeEnter: redirectToDashboardHandler,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (!auth.state.isLoggedIn && to.name !== 'Login')
    return next({ name: 'Login' })
  next()
})

export default router
