import axios from 'axios'
import swalMixins from '../../mixins/swal'
import genericMixins from '../../mixins/generic'

export default {
  state: {
    question: null, //chosen question
    questions: [],
    filtered: [],
  },
  getters: {
    questions: state => state.questions,
    question: state => state.question,
    questionFiltered: state => state.filtered,
  },
  mutations: {
    //sets all questions
    'questions/set': (state, payload) => {
      state.questions = payload
      state.questionFiltered = [...state.questions]
    },
    //sets one question
    'question/set': (state, payload) => (state.question = payload),
    //filters the question's array by question's key and question's val
    'questions/filter': (state, { key, val }) => {
      state.questionFiltered = !val
        ? [...state.questions]
        : state.questions.filter(f => f[key] === val)
    },
    //store one question
    'question/store': (state, payload) => {
      state.questions.push(payload)
    },
    //store many question
    'question/storeMany': (state, payload) => {
      state.questions.push(...payload)
    },
    //destroys one question
    'question/destroy': (state, id) =>
      (state.questions = state.questions.filter(item => {
        return item._id !== id
      })),
    //updates one question
    'question/update': (state, payload) => {
      state.questions = state.questions.map(item => {
        if (item._id === payload._id) {
          return payload
        }
        return item
      })
    },
    'question/clearData': state => {
      state.question = null
    },
    'questions/clearData': state => {
      state.question = null
      state.questions = []
      state.filtered = []
      state.index = 0
    },
  },
  actions: {
    //fetch all questions
    'question/index': async context => {
      try {
        const { data } = await axios.get('/question')
        context.commit('questions/set', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //fetch one question by id
    'question/show': async (context, id) => {
      try {
        let { data } = await axios.get('/question/' + id)
        context.commit('question/set', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //fetch my questions
    'question/showMany': async context => {
      try {
        const { data } = await axios.get('/question/many')
        context.commit('questions/set', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //stores one question
    'question/store': async (context, payload) => {
      try {
        let { data } = await axios.post('/question', { ...payload })
        context.commit('question/store', data)
        context.commit('game/question/store', data)
        await context.dispatch('game/update', {
          ...context.getters.game,
          questions: [...context.getters.game.questions],
          _id: payload.game,
        })
        console.log(data)
      } catch (e) {
        console.log(e)
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //stores many questions
    'question/storeMany': async (context, payload) => {
      try {
        let { data } = await axios.post('/question/many', payload.questions)
        context.commit('question/storeMany', data)
        context.commit('game/question/storMany', data)
        await context.dispatch('game/update', {
          _id: context.getters.game._id,
          questions: [...context.getters.game.questions],
        })
      } catch (e) {
        console.log(e)
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //destroys one question
    'question/destroy': async (context, id) => {
      try {
        await axios.delete('/question/' + id)
        context.commit('question/destroy', id)
        context.commit('game/question/destroy', id)
      } catch (e) {
        console.log(e)
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //destroys one question
    'question/only/destroy': async (context, id) => {
      try {
        await axios.delete('/question/' + id)
        context.commit('question/destroy', id)
      } catch (e) {
        console.log(e)
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //updates one question by its id
    'question/update': async (context, payload) => {
      try {
        await axios.put('/question/' + payload._id, payload)
        context.commit('question/update', payload)
        context.commit('game/question/update', payload)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //updates question labels by its id
    'question/update/category': async (context, payload) => {
      try {
        console.log(payload)
        const { data } = await axios.put('/question/' + payload._id, payload)
        console.log(data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //replicate one question by its id
    'question/replicate': async (context, { id }) => {
      try {
        const { data } = await axios.post('/question/replicate/' + id)
        context.commit('question/store', data)
        context.commit('game/question/store', data)
        await context.dispatch('game/update', {
          ...context.getters.game,
          questions: [...context.getters.game.questions],
          _id: data.game,
        })
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //replicate one question by its id
    'question/displayRegularUser': async (context, payload) => {
      try {
        await axios.put('/question/' + payload._id, payload)
        context.commit('question/update', payload)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
  },
}
