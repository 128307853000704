<template>
  <div>
    <v-container>
      <v-data-table
        v-model="selected"
        :show-select="this.fromImportQuestionsPage"
        :headers="isAdmin ? [...headers, ...adminHeaders] : headers"
        :items="questionsFilter"
        :search="search"
        :loading="loading"
        :items-per-page="this.fromImportQuestionsPage ? 5 : 15"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="_id"
        :show-expand="isAdmin"
        class="elevation-1"
        :footer-props="{ 'items-per-page-options': [25, 50, 100, 200, 250] }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                icon
                :label="$t('generics.search')"
                single-line
                hide-details
              />
            </div>
            <div class="mx-5 mt-6">
              <v-autocomplete
                v-model="searchByCategories"
                auto-select-first
                chips
                clearable
                deletable-chips
                multiple
                small-chips
                item-text="name"
                :label="$t('generics.searchByCategories')"
                :items="categories"
              />
            </div>
            <v-spacer></v-spacer>
            <tooltip-icon
              :tooltipText="$t('categories.editLabelsTooltip')"
              :icon="'mdi-pencil'"
              :fab="true"
              color="primary"
              @action="editCategories = true"
            />
            <v-icon color="primary" @click="loadData" v-text="'mdi-refresh'" />
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row class="pa-3">
              <v-col cols="1">
                <div class="d-flex justify-start info--text ma-1">
                  {{ $t('answers.answers') }}:
                </div>
              </v-col>
              <v-col
                cols="1"
                v-for="(ans, i) in item.question.answers"
                :key="ans._id"
                class="d-flex"
              >
                {{ i + 1 }}.
                <div
                  :class="` ${
                    ans['correct'] ? 'success' : 'error'
                  }--text answers`"
                >
                  {{ ans['ans'] }}
                </div>
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-slot:item.categories="{ item }">
          <v-chip-group column>
            <v-chip v-for="category in item.categories" :key="category._id">
              {{ category.name }}
            </v-chip>
          </v-chip-group>
        </template>
        <template v-slot:item.user="{ item }">
          {{ getEmailUser(item.user) }}
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ formatTime(item.createdAt) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <tooltip-icon
            icon="mdi-eye"
            :color="item.displayRegularUser ? 'green' : 'red'"
            :tooltipText="$t(`questions.showAndDoNotShowToRegularUserTooltip`)"
            validator
            @action="disabled => saveShow(item, disabled)"
          />

          <tooltip-icon
            :tooltipText="$t('questions.editQuestionTooltip')"
            :icon="'mdi-pencil'"
            :fab="true"
            @action="editItem(item)"
          />

          <tooltip-icon
            :tooltipText="$t('questions.deleteQuestionTooltip')"
            :icon="'mdi-delete'"
            color="red"
            :fab="true"
            validator
            @action="disabled => deleteItem(item, disabled)"
          />
        </template>
      </v-data-table>
      <question-category-handler
        v-if="editDialog"
        v-model="editDialog"
        :question="current"
      />
      <categories-handler
        v-if="editCategories"
        v-model="editCategories"
        @loadData="loadDataAdmin"
      />
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash'

import SwalMixins from '@/mixins/swal'
import GenericMixins from '@/mixins/generic'
import TooltipIcon from '@/components/buttons/TooltipIcon.vue'
import QuestionCategoryHandler from '../../components/handler/QuestionCategoryHandler.vue'
import CategoriesHandler from '@/components/handler/CategoriesHandler.vue'

export default {
  components: {
    TooltipIcon,
    QuestionCategoryHandler,
    CategoriesHandler,
  },
  name: 'AllQuestions',
  mixins: [SwalMixins, GenericMixins],
  props: {
    fromImportQuestionsPage: { type: Boolean, default: false },
  },
  data() {
    return {
      selected: [],
      expanded: [],
      singleExpand: true,
      loading: false,
      current: null,
      search: '',
      editDialog: false,
      editCategories: false,
      searchByCategories: [],
      headers: [
        { text: this.$t('questions.questions'), value: 'question.que' },
        { text: this.$t('questions.categories'), value: 'categories' },
      ],
      adminHeaders: !this.fromImportQuestionsPage
        ? [
            { text: this.$t('questions.createByUser'), value: 'user' },
            { text: this.$t('generics.createdAt'), value: 'createdAt' },
            {
              text: this.$t('generics.action'),
              value: 'actions',
              width: '10%',
            },
          ]
        : [],
    }
  },
  computed: {
    categories() {
      return this.$store.getters.categories
    },
    questions() {
      return this.$store.getters.questions
    },
    questionsFilter() {
      return this.questions.filter(q => {
        if (
          this.searchByCategories.length &&
          !_.intersection(
            q.categories?.map(c => c.name),
            [...this.searchByCategories]
          ).length
        ) {
          return false
        }
        if (this.search) {
          return q.question.que.includes(this.search)
        }
        return true
      })
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
  },
  methods: {
    getEmailUser(id) {
      const user = this.$store.getters.userById(id)
      return user?.email
    },
    async saveShow(item, disabled) {
      await this.$store.dispatch('question/displayRegularUser', {
        ...item,
        displayRegularUser: !item.displayRegularUser,
      })
      disabled()
    },
    async loadData() {
      this.loading = true
      await this.$store.dispatch('question/showMany')
      await this.$store.dispatch('category/index')
      this.loading = false
    },
    async loadDataAdmin() {
      this.loading = true
      await this.$store.dispatch('category/index')
      await this.$store.dispatch('question/index')
      await this.$store.dispatch('user/index')
      this.loading = false
    },
    editItem(item) {
      this.current = item
      this.editDialog = true
    },
    async deleteItem(item, disabled) {
      let { isConfirmed } = await this.createSwal({
        icon: 'warning',
        title: `${this.$t('generics.delete')}? \n ${item.question.que}`,
      })
      if (isConfirmed) {
        await this.$store.dispatch('question/only/destroy', item._id)
        this.timeoutSwal({ icon: 'success', title: '' })
      }
      disabled()
    },
  },
  async mounted() {
    this.$store.commit('title/set', this.$t(`route.${this.$route.name}`))
    if (this.isAdmin) {
      this.loadDataAdmin()
    } else {
      this.loadData()
    }
    // await this.$store.dispatch("label/index");
    // await this.$store.dispatch("user/index");
    // if (!this.questions.length) {
    //   await this.loadData();
    // }
  },
  watch: {
    selected() {
      this.$emit('onSelect', this.selected)
    },
  },
}
</script>
