import I18n from '@/i18n'
const Swal = require('sweetalert2')

const swalMixins = {
  methods: {
    createSwal({
      icon,
      title,
      confirmColor,
      cancelColor = '#2196F3',
      text,
      closeable,
      lang = 'he',
      confirmButtonText = I18n.t('generics.ok'),
      cancelButtonText = I18n.t('generics.cancel'),
    }) {
      if (!confirmColor) confirmColor = '#2196F3'
      if (closeable === null || closeable === undefined) closeable = true
      let direction = lang === 'he' ? 'rtl' : 'ltr'
      return Swal.fire({
        icon,
        title: `<p style="direction: ${direction} !important;">${title}</p>`,
        html: text
          ? `<p style="direction: ${direction} !important;">${text}</p>`
          : '',
        confirmButtonText,
        confirmButtonColor: confirmColor,
        showCancelButton: true,
        cancelButtonText,
        cancelButtonColor: cancelColor,
        allowOutsideClick: closeable,
      })
    },

    timeoutSwal({ icon, title, color, text = '', lang = 'he', timer = 1500 }) {
      if (!color) color = '#2196F3'
      let direction = lang === 'he' ? 'rtl' : 'ltr'
      return Swal.fire({
        icon,
        title: `<p style="direction: ${direction} !important;">${title}</p>`,
        html: text
          ? `<p style="direction: ${direction} !important;">${text}</p>`
          : '',
        showCancelButton: false,
        showConfirmButton: false,
        timer,
        allowOutsideClick: false,
        position: 'center',
      })
    },

    createInputSwaldata({
      title,
      // inputType = "text",
      color = '#2196F3',
      presistant = false,
      timer = null,
      lang = 'he',
      confirmButtonText = I18n.t('generics.ok'),
      cancelButtonText = I18n.t('generics.cancel'),
      errorMessage = I18n.t('errors.notEmpty'),
    }) {
      let direction = lang === 'he' ? 'rtl' : 'ltr'
      let data = {
        title,
        // input: inputType,
        showCancelButton: true,
        confirmButtonColor: color,
        inputValidator: value => {
          if (!value) return errorMessage
        },
        showConfirmButton: true,
        confirmButtonText,
        cancelButtonText,
        allowOutsideClick: presistant,
        reverseButtons: true,
        html: `<input style="direction: ${direction} !important;" id="swal-input" class="swal2-input">`,
        focusConfirm: false,
        preConfirm: () => {
          return document.getElementById('swal-input').value
        },
      }
      if (timer) {
        data['timer'] = timer
        data['timerProgressBar'] = true
      }
      return Swal.fire({ ...data })
    },
  },
}

export default swalMixins
