<template>
  <div>
    <my-dialog
      :title="$t('ניהול קטגוריות')"
      v-model="openModal"
      :fillHeight="false"
    >
      <template v-slot:content>
        <v-card flat>
          <v-card-title class="d-flex align-center">
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('categories.add')"
                  v-model="category"
                  autofocus
                  ref="categoryText"
                  @keydown.enter="create"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  :label="$t('generics.search')"
                  append-icon="mdi-magnify"
                  icon
                  single-line
                  hide-details
                ></v-text-field
              ></v-col>
              <v-col cols="1" class="center-all">
                <v-icon
                  color="primary"
                  @click="addDialog = true"
                  v-text="'mdi-plus'"
                />
                <v-icon
                  color="primary"
                  @click="loadData"
                  v-text="'mdi-refresh'"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="categories"
            :search="search"
            :loading="loading"
            :items-per-page="5"
          >
            <template v-slot:item.createdAt="{ item }">
              {{ formatTime(item.createdAt) }}
            </template>
            <!-- <template v-slot:item.updatedAt="{ item }">
              {{ formatTime(item.updatedAt) }}
            </template> -->
            <template v-slot:item.actions="{ item }">
              <v-icon color="error" small @click="deleteItem(item)"
                >mdi-delete</v-icon
              >
            </template>
          </v-data-table>
        </v-card>
        <v-row v-if="err">
          <v-col class="error--text text-center">
            {{ err }}
          </v-col>
        </v-row>
      </template>
    </my-dialog>
  </div>
</template>
<script>
import SwalMixins from '@/mixins/swal'
import GenericMixins from '@/mixins/generic'
import MyDialog from '@/components/MyDialog.vue'

export default {
  name: 'categories-handler',
  mixins: [SwalMixins, GenericMixins],

  components: { MyDialog },
  props: { value: Boolean },
  data() {
    return {
      loading: false,
      headers: [
        //TODO: add headers here
        { text: this.$t('generics.name'), value: 'name' },
        { text: this.$t('generics.createdAt'), value: 'createdAt' },
        // { text: this.$t("categories.questions"), value: "updatedAt" },
        {
          text: this.$t('generics.action'),
          value: 'actions',
          width: '2%',
        },
      ],
      current: null,
      search: '',
      category: '',
      err: null,
    }
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    categories() {
      return this.$store.getters.categories
    },
  },
  methods: {
    async create() {
      try {
        if (this.category.trim().length) {
          await this.$store.dispatch('category/store', {
            name: this.category,
          })
        }
        this.$refs.categoryText.clearableCallback()
      } catch (e) {
        console.log(e)
      }
    },
    async loadData() {
      this.loading = true
      await this.$store.dispatch('category/index')
      this.loading = false
    },
    async deleteItem(item) {
      let { isConfirmed } = await this.createSwal({
        icon: 'warning',
        title: `${this.$t('categories.delete')} ${item.name}?`,
      })
      if (isConfirmed) {
        await this.$store.dispatch('category/destroy', item._id)
        this.$emit('loadData')
      }
    },
    async mounted() {
      if (!this.categories.length) {
        await this.loadData()
      }
    },
  },
}
</script>
